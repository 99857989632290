<template>
	<b-modal size="xxl" ref="modalDPS" @show="init_component">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('monte.prepare_carnet') }}</h2>
		</template>
		<template slot="default">
			<div class="row">
				<div class="col-auto ml-auto mt-3">
					<button v-if="stallion_mare_selected.length > 0" class="btn btn-secondary rounded-pill" @click="prepareCarnet">
						{{ $t('monte.prepare_carnet') }}
						<font-awesome-icon :icon="['fal', 'sync']" :spin="prepare_working"/>
					</button>
				</div>
			</div>
			<CustomTable
	            id_table="dps_stallion_mare"
	            :items="stallion_mare"
	            :busy.sync="table_busy"
	            primaryKey="seasonmarestallion_id"
	            :hasExportOption="false"
				:selected_ids.sync="stallion_mare_selected"
            	:hasConfTableAccess="false"
            	:hasPaginationSelect="false"
	        />
	    </template>
		<template slot="modal-footer" class="justify-content-center">
			<div></div>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
    import DPS from "@/mixins/DPS.js"

	export default {
		name: 'StallionMare',
		mixins: [DPS],
		props: ['season_id', 'dps_id'],
		data () {
			return {
				stallion_mare: [],
				stallion_mare_selected: [],
				processing: false,
				table_busy: false,
				prepare_working: false,
			}
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.stallion_mare = await this.getStallionMareSaillie(this.season_id, this.dps_id)
				this.table_busy = false
			},

			openModal() {
				this.$refs.modalDPS.show()
			},

			async prepareCarnet() {
				this.prepare_working = true
				await this.prepareCarnetSaillie(this.dps_id, this.stallion_mare_selected)
				this.prepare_working = false
				this.stallion_mare_selected = []
				this.$refs.modalDPS.hide()
				this.$emit("dps_prepared", true)
			}
		},

		components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'), 
		}
	}

</script>